import { ReactNode, useId } from "react";
import { useFormContext } from "react-hook-form";
import { GenericObject, MinimalFieldProp } from "~/old-app/types/common";
import { cls } from "~/old-app/utils/helpers/cls";
import { ErrorField } from "./error-field";
import { Input, InputProps } from "./input";
import { Label, LabelProps } from "./label";

type Props<T extends GenericObject> = LabelProps &
  InputProps &
  MinimalFieldProp<T> & {
    className?: string;
    inputClassName?: string;
    end?: ReactNode;
    start?: ReactNode;
  };

export const InputField = <T extends GenericObject>({
  label,
  id: idProp,
  mask,
  name,
  required,
  labelClassName,
  className,
  end,
  ...props
}: Props<T>) => {
  const id = useId();

  const {
    formState: { errors },
    register,
  } = useFormContext();

  const error = errors?.[name]?.message?.toString();

  return (
    <div className={cls("flex flex-col relative w-full")}>
      {!!label && (
        <Label
          className=""
          label={label}
          required={required}
          labelClassName={labelClassName}
        >
          {label}
        </Label>
      )}
      <div className="flex gap-2">
        <Input
          id={idProp || id}
          {...props}
          {...register(name)}
          className={cls("", className)}
          error={error}
          mask={mask}
        />
        {end}
      </div>
      <ErrorField error={error} />
    </div>
  );
};
